import React from 'react'
import { Link } from "gatsby"
import LogoReversed from "../images/Enabli-logo-white.svg"
// import Twitter from "../components/svg/Twitter"
import Linkedin from "../components/svg/Linkedin"
// import Insta from "../components/svg/Insta"

export default function Header() {

  return (
    
      <footer>
        <div className="container">
          <div className="row">
              <div className="footer-top">
                  <div className="footer-col-lg">
                    <Link to="/" className="logo">
                      <img src={LogoReversed} alt="Enabli logo" />
                    </Link>
                  </div>
                  <div className="footer-col footer-links">
                      <Link to="/">Home</Link>
                      <Link to="/#features" className='nav-link'>Features</Link>
                      <Link to="/#pricing" className='nav-link'>Pricing</Link>
                      <Link to="/faqs">FAQs</Link>
                  </div>
                  <div className="footer-col footer-links">
                      <Link to="/contact">Contact Us</Link>
                      <a href="https://app.enabli.com/">Sign in</a>
                  </div>
                  <div className="footer-col footer-links socials">
                      <a href="https://www.linkedin.com/company/enabli" target="_blank" rel="noopener noreferrer"><Linkedin /></a>
                  </div>
              </div>
              <div className="footer-bottom small">
                  © {new Date().getFullYear()} Enabli Pty Ltd. All rights reserved. <Link to="/terms">Terms of service</Link>, <Link to="/privacy">Privacy Policy</Link>
              </div>
          </div>
        </div>
      </footer>
  )
}