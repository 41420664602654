import React, { useCallback, useState } from 'react'
import { Link } from "gatsby"
import Logo from "../images/Enabli-logo.svg"
import useWindowSize from '../hooks/useWindowSize'
import classNames from 'classnames'

export default function Header() {
  const { width } = useWindowSize();
  const useSmallMenu = width < 768;

  const [isMobileOpen, setMobileOpen] = useState(false);
  const onToggleMobileOpen = useCallback(() => setMobileOpen(!isMobileOpen), [isMobileOpen]);

  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="navbar">
            <Link to="/" className="logo">
              <img src={Logo} alt="Enabli logo" />
            </Link>

            {(!useSmallMenu || isMobileOpen) &&
            (<nav className={classNames({'mobile-menu': useSmallMenu})}>
              <Link to="/#features" className='nav-link'>Features</Link>
              <Link to="/#pricing" className='nav-link'>Pricing</Link>
              <Link to="/faqs" className='nav-link'>FAQs</Link>
              <a href="https://app.enabli.com/" className='btn btn-sm btn-line mr-75'>
                Sign in
              </a>
              <Link to="/contact" className='btn btn-sm btn-blue'>
                Contact
              </Link>
            </nav>)}
            
            {useSmallMenu &&
            (<button onClick={onToggleMobileOpen} className='btn-mob-nav'>
              {isMobileOpen ? (<div id="nav-icon4" className="open">
                <span></span>
                <span></span>
                <span></span>
              </div>) : (<div id="nav-icon4">
                <span></span>
                <span></span>
                <span></span>
              </div>)}
            </button>)}
            
          </div>
        </div>
      </div>
    </header>
  )
}
